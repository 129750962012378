<template>
    <page title="孩子列表" :hasBack="true" textAlign="left">
        <div class="student-wrapper">
            <div
                class="student-item"
                v-for="item in studentList"
                :key="item.studentUserid"
                @click="viewDetail(item.studentUserid)"
            >
                <div class="left">
                    <!--                    <img v-if="item.photo" :src="item.photo" alt="">-->
                    <van-image round v-if="item.photo" class="avatar" fit="cover" :src="item.photo" />
                    <img v-else class="avatar" src="@/assets/images/user/default_avatar.png" alt="" />
                    <div class="name">{{ item.studentName }}</div>
                </div>
                <van-icon name="arrow" />
            </div>
        </div>
<!--        <div class="add-wrapper">-->
<!--            <img src="@/assets/images/studentList/add.png" @click="addStudent" alt="" />-->
<!--            <div class="tips">点击添加学生</div>-->
<!--        </div>-->
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { Icon, Image as VanImage } from 'vant';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import commonRequest from '@/services';

export default {
    name: 'StudentList',
    components: {
        [Page.name]: Page,
        [Icon.name]: Icon,
        [VanImage.name]: VanImage,
    },
    setup() {
        const router = useRouter();
        const addStudent = () => {
            router.push({
                path: '/addStudent',
            });
        };
        const studentList = ref([]);
        commonRequest({ action: 11009 }).then((res) => {
            if (res.data) {
                studentList.value = res.data;
            }
        });
        const viewDetail = (id) => {
            router.push({
                path: '/studentDetail',
                query: { id },
            });
        };
        return {
            studentList,
            addStudent,
            viewDetail,
        };
    },
};
</script>
<style lang="less" scoped>
.student-item {
    height: 51px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 12px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .left {
        display: flex;
        align-items: center;
        .avatar {
            width: 40px;
            height: 40px;
            /*border-radius: 50%;*/
        }
        .name {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
            margin-left: 12px;
        }
    }
    /deep/ .van-icon-arrow {
        color: #333333;
    }
}
.add-wrapper {
    margin-top: 100px;
    text-align: center;
    img {
        width: 65px;
    }
    .tips {
        font-size: 12px;
        font-family: PingFang SC, serif;
        line-height: 17px;
        color: #999999;
        margin-top: 10px;
    }
}
</style>
